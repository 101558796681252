import './creamLines.css';
import React from 'react';

const CreamLines = () => {
  return (
    <div className="CreamLines">
     <hr id='half'/>
     <hr id='full'/>
    </div>
  );
};

export default CreamLines;
