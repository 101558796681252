// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base styles for both laptop and phone */
#half {
  width: 50%;
  background-color: #d9d2b6;
  height: 3px;
  margin-left: 0;
  border-width: 0;
  margin-bottom: 2%;
}
#full {
  width: 100%;
  background-color: #d9d2b6;
  border-width: 0;
  height: 3px;
  margin-left: 0;
}

/* Media query for phones */
@media only screen and (max-width: 767px) {
  #half,
  #full {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/creamLines.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;EACE,UAAU;EACV,yBAAyB;EACzB,WAAW;EACX,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,yBAAyB;EACzB,eAAe;EACf,WAAW;EACX,cAAc;AAChB;;AAEA,2BAA2B;AAC3B;EACE;;IAEE,UAAU;EACZ;AACF","sourcesContent":["/* Base styles for both laptop and phone */\n#half {\n  width: 50%;\n  background-color: #d9d2b6;\n  height: 3px;\n  margin-left: 0;\n  border-width: 0;\n  margin-bottom: 2%;\n}\n#full {\n  width: 100%;\n  background-color: #d9d2b6;\n  border-width: 0;\n  height: 3px;\n  margin-left: 0;\n}\n\n/* Media query for phones */\n@media only screen and (max-width: 767px) {\n  #half,\n  #full {\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
