// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base styles for both laptop and phone */
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 2%;
  margin-right: 2%;
}

.pageLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.menuPages {
  font-family: Truculenta;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.5em;
}

.menuPages:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* Media query for phones */
@media only screen and (max-width: 767px) {
  .menuPages{
    font-size: 12px;
    padding: 1em
  }
}




`, "",{"version":3,"sources":["webpack://./src/Components/Header.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,0BAA0B;AAC5B;;AAEA,2BAA2B;AAC3B;EACE;IACE,eAAe;IACf;EACF;AACF","sourcesContent":["/* Base styles for both laptop and phone */\n.header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-left: 2%;\n  margin-right: 2%;\n}\n\n.pageLinks {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n  align-items: flex-end;\n}\n\n.menuPages {\n  font-family: Truculenta;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  padding: 0.5em;\n}\n\n.menuPages:hover {\n  cursor: pointer;\n  text-decoration: underline;\n}\n\n/* Media query for phones */\n@media only screen and (max-width: 767px) {\n  .menuPages{\n    font-size: 12px;\n    padding: 1em\n  }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
